/**
 *  This remote toggle class is deprecated in favour of new stimulus controller [app/frontend/controllers/remote_toggle_controller.js]
 *  - However, this file handles bootstrap toggle checkboxes which is still being used in site admin's pages
 *  - And company admin's Points and Rewards pages
 *  - 
 */

import Swal from 'sweetalert2';

export default function remoteCheckboxToggle() {
  $(".form-check-input").each(function(_, el) {
    $(el).on("change", function() {
      handleChange($(el));
    });
  });
}

function handleChange($target) {
  let endpoint = $target.data('endpoint');
  let checkedValue = $target.prop("checked");
  let confirmText = checkedValue 
    ? 'Are you sure you want to enable this theme?' 
    : 'Are you sure you want to disable this theme?';

  if ($target.data('confirm-text')) {
    Swal.fire({
      title: confirmText,
      confirmButtonText: 'Yes',
      showCancelButton: true,
      showLoaderOnConfirm: true,
    }).then(function(result) {
      if (!result.value) {
        reverseToggle($target, checkedValue);
        return false;
      } else {
        sendRequest($target, endpoint, checkedValue);
      }
    });
  } else {
    sendRequest($target, endpoint, checkedValue);
  }
}

function sendRequest(target, endpoint, checkedValue){
  var data = {}, type = null;
  if (target.data('invert-values') === true) { // used as `invert_values` in view
    checkedValue = !checkedValue;
  }
  data[target.prop('name')] = checkedValue;
  type = target.data('method') || "POST"

  if(endpoint) {
    $.ajax({
      url: endpoint,
      type: type,
      data: data
    })
  } else {
    console.warn("Endpoint data attribute is missing on checkbox")
  }
}

function reverseToggle(target, checkedValue){
  let parentId = target.parents()[0].id;

  // Setting the UI back
  if (!checkedValue){ //ON
    $('#' + parentId + '.remoteCheckboxToggle').attr('checked', 'checked');
  }
  else{ // OFF
    $('#' + parentId + '.remoteCheckboxToggle').removeAttr('checked');
  }
}
