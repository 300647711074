import '~/scripts/integrations/jquery-extended';

export default (function() {
  function Settings() {
    this.turnOnToggles();
  }

  Settings.prototype.turnOnToggles = function() {
    $('#hide-points .form-check-input').on('change', function() {
      var $target = $(this);
      var data = {};
      var checkedValue = this.checked;
  
      if ($target.data('invert-values') === true) {
        checkedValue = !checkedValue;
      }
  
      data[$target.data('setting')] = checkedValue;
  
      $.ajax({
        url: '/' + $('body').data('name') + '/company/update_settings',
        type: "POST",
        data: { settings: data },
        success: function() {
          window.R.utils.renderFeedbackMessageAfterSave("Saved!", $target.closest(".form-check-input"), 3000, "success");
        }
      });
    });
  };

  return Settings;
})();
